.about {
	padding-top: 2%;
	padding-bottom: 2%;
	position: relative;
}
.abt {
	margin-top: 40px;
}
.img_abt {
	width: 50%;
	height: 440px;
	background-repeat: no-repeat;
	background-size: cover;
	/* background-attachment: fixed; */
}
.infs {
	padding-left: 50px;
	width: 50%;
}
.tnxs p {
	position: relative;
	padding: 20px;
	margin: 10px 0;
	color: #64607d;
}
.tnxs p::before {
	content: "";
	width: 5px;
	height: 100%;
	background-color: #ff5043;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 5px;
}
.tnxs p ol {
	padding-left: 15px;
}
.sl1 {
	position: absolute;
	top: -40%;
	right: 0;
}
li {
	/* margin-bottom: 0 !important; */
}
li div {
	flex: initial !important;
}
.bvb {
	padding-top: 10px;
}
.bvb div {
	flex: 1;
}
.bvb p {
	margin-bottom: 20px;
	font-size: 0.9em;
	line-height: 25px;
	font-weight: 500;
	color: #64607d;
}
.bvb div:nth-child(2) {
	text-align: center;
}

.bvb div .pimg {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	/* margin-left: auto;
  margin-right: auto; */
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pimg img {
	width: 120px;
	height: 120px;
	border-radius: 50%;
}
@media (max-width: 760px) {
	.img_abt,
	.infs {
		width: 100%;
		padding-left: 0;
	}
	.img_abt {
		height: 300px;
		background-attachment: unset;
		/* width: 100vw;
    margin-left: -5%; */
	}
}
